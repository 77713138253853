<template>
  <div
    class="container border rounded"
    style="padding: 10px; background-color: #e9ecef"
  >
    <div class="row">
      <div class="col">
        <div v-for="cat in mcats" :key="cat.id" class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              @click="update(cat.id)"
            />
            <h5>
              <span class="badge" :style="cat.colour">{{ cat.category }}</span>
            </h5>
          </label>
        </div>
      </div>
      <div class="col">
        <div v-for="cat in gcats" :key="cat.id" class="form-check">
          <label class="form-check-label">
            <input
              checked
              type="checkbox"
              class="form-check-input"
              @click="update(cat.id)"
            />
            <h5>
              <span class="badge" :style="cat.colour">{{ cat.category }}</span>
            </h5>
          </label>
        </div>
      </div>
    </div>
    <div>
      <br />
      <b-button variant="secondary" size="sm" @click="showModal"
        >Edit/New Category</b-button
      >
    </div>
    <b-modal v-model="modalShow">
      <template v-slot:modal-header
        >New/Edit Category
        <h5>
          <span class="pull-right badge" :style="truecolour">{{ name }}</span>
        </h5>
      </template>
      <div class="row mx-auto">
        <div v-for="cat in cats" :key="cat.id">
          <h5>
            <span
              @click="editCategory(cat)"
              class="badge"
              style="cursor: pointer"
              :style="cat.colour"
              >{{ cat.category }}</span
            >&nbsp;&nbsp;&nbsp;
          </h5>
        </div>
      </div>
      <div class="form-group">
        <label>Name:</label>
        <input type="text" class="form-control" v-model="name" />
      </div>
      <div class="form-group">
        <label>Colour:</label>
        <input type="color" class="form-control" v-model="colour" />
      </div>
      <div class="form-group custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
          v-model="text"
        />
        <label class="custom-control-label" for="customSwitch1"
          >Black/White Text</label
        >
      </div>
      <template v-slot:modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button
          :disabled="btnUpdateDisable"
          class="loading"
          @click="updateCat"
          variant="warning"
          >Update Category</b-button
        >
        <b-button
          :disabled="btnNewDisable"
          class="loading"
          @click="newCat"
          variant="success"
          >New Category</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["guideline"],
  data() {
    return {
      modalShow: false,
      name: "",
      colour: "",
      text: true,
      groan: true,
      cats: [],
      gcats: [],
      selectCat: "",
    };
  },
  created() {
    this.getCats();
  },
  methods: {
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
      this.name = "";
      this.colour = "";
      this.selectCat = "";
      this.text = true;
      this.groan = true;
    },
    getCats() {
      axios.get("/category/show/" + this.guideline.id).then((response) => {
        this.cats = response.data.cats;
        this.gcats = response.data.gcats;
      });
    },
    editCategory(cat) {
      this.name = cat.category;
      this.colour = cat.colour.substring(18, 25);
      this.text = cat.colour.slice(-2) == "e;" ? false : true;
      this.selectCat = cat.id;
    },
    updateCat() {
      this.groan = false;
      axios
        .post("/category/updatecat", {
          name: this.name,
          colour: this.truecolour,
          id: this.selectCat,
        })
        .then(() => {
          this.hideModal();
          this.getCats();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newCat() {
      this.groan = false;
      axios
        .post("/category/create", {
          name: this.name,
          colour: this.truecolour,
        })
        .then(() => {
          this.hideModal();
          this.getCats();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update(id) {
      axios
        .post("/category/update", {
          category: id,
          guideline: this.guideline.id,
        })
        .then(() => {
          this.getCats();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    btnNewDisable: function () {
      return !(
        this.name != "" &&
        this.colour != "" &&
        this.groan &&
        this.selectCat == ""
      );
    },
    btnUpdateDisable: function () {
      return !(
        this.name != "" &&
        this.colour != "" &&
        this.groan &&
        this.selectCat != ""
      );
    },
    truecolour: function () {
      let textcolour = this.text ? "black" : "white";
      return (
        "background-color: " + this.colour + "; color: " + textcolour + ";"
      );
    },
    mcats: function () {
      const ids = this.gcats.map((e) => e.id);
      return this.cats.filter((e) => !ids.includes(e.id));
    },
  },
};
</script>