<template>
  <div>
    <div class="form-group">
      <label>Guideline Name:</label>
      <input
        type="text"
        class="form-control"
        :class="nameClean"
        v-model="name"
        aria-describedby="nameHelp"
      />
      <small v-if="nameValid" id="nameHelp" class="form-text text-danger">{{
        nameValid
      }}</small>
      <br />
      <label>This Version:</label>
      <input
        type="text"
        class="form-control"
        :class="versionClean"
        v-model="version"
      />
      <br />
      <label>Link:</label>
      <input
        type="text"
        class="form-control"
        :class="linkClean"
        v-model="completeUrl"
        disabled
      />
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input
          type="checkbox"
          class="form-check-input"
          :checked="published"
          :class="publishedClean"
          v-model="published"
        />Published
      </label>
      <button
        type="button"
        class="btn btn-info btn-sm float-right"
        v-clipboard:copy="completeUrl"
      >
        Copy Link
      </button>
    </div>
    <br />
    <label v-if="!guideline.reverselu">Published Version:</label>
    <select
      v-if="!guideline.reverselu"
      class="custom-select"
      :class="pubversionClean"
      v-model="published_version"
    >
      <option
        v-for="version in versions"
        :key="version.id"
        :value="version.id"
        :selected="version.id == published_version"
      >
        {{ version.canonical_id }}
      </option>
    </select>
    <label v-if="guideline.reverselu">Source URL:</label>
    <input
      v-if="guideline.reverselu"
      type="text"
      class="form-control"
      :class="urlClean"
      v-model="reverselu_url"
      aria-describedby="pdfWarning"
    />
    <small v-if="pdfWarning" id="pdfWarning" class="form-text text-danger">{{
      pdfWarning
    }}</small>
    <br />
    <br />
    <div class="row">
      <tr-new-version
        v-if="!guideline.reverselu"
        :guideline_id="guideline.id"
      ></tr-new-version>
      <div class="col" v-if="guideline.reverselu">
        <b-button
          variant="danger"
          size="sm"
          @click="pull"
          :disabled="this.urlClean"
          v-if="!pullDisabled"
          >Check myUCLH</b-button
        >
        <b-spinner
          v-if="pullDisabled"
          variant="danger"
          type="grow"
          label="Spinning"
        ></b-spinner>
      </div>
      <div class="col">
        <b-button
          variant="success"
          size="sm"
          class="float-right"
          :disabled="updateDisabled"
          @click="update"
          >Update</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["guideline", "versions", "pub_version"],
  data() {
    return {
      name: "",
      url: "",
      published: "",
      published_version: "",
      version: "",
      nameValid: "",
      versionCleanHolder: "",
      versionId: "",
      reverselu_url: "",
      pullDisabled: false,
      pdfWarning: false,
    };
  },
  created() {
    this.name = this.guideline.title;
    this.url = this.guideline.slug;
    this.published = this.guideline.published;
    this.published_version = this.guideline.published_version;
    this.version = this.pub_version.version;
    this.versionId = this.pub_version.id;
    this.versionCleanHolder = this.pub_version.version;
    this.reverselu_url = this.guideline.reverselu_url;
    Event.$on("preview", (data) => {
      this.version = data.version;
      this.versionId = data.id;
      this.versionCleanHolder = data.version;
    });
  },
  computed: {
    completeUrl: function () {
      return "https://uclh.teamread.co.uk/guideline/" + this.url;
    },
    nameClean: function () {
      return this.name == this.guideline.title ? false : "border border-danger";
    },
    urlClean: function () {
      return this.reverselu_url == this.guideline.reverselu_url
        ? false
        : "border border-danger";
    },
    versionClean: function () {
      return this.version == this.versionCleanHolder
        ? false
        : "border border-danger";
    },
    linkClean: function () {
      return this.url == this.guideline.slug ? false : "border border-danger";
    },
    publishedClean: function () {
      return this.published == this.guideline.published ? false : "redoutline";
    },
    pubversionClean: function () {
      return this.published_version == this.guideline.published_version
        ? false
        : "border border-danger";
    },
    updateDisabled: function () {
      return this.nameClean ||
        this.versionClean ||
        this.linkClean ||
        this.publishedClean ||
        this.pubversionClean ||
        this.urlClean
        ? false
        : true;
    },
  },
  methods: {
    update() {
      var title = this.nameClean ? this.name : null;
      var version = this.versionClean ? this.version : null;
      var published = this.publishedClean ? this.published : null;
      var reverselu_url = this.urlClean ? this.reverselu_url : null;
      var versionId = this.versionId;
      var published_version = this.pubversionClean
        ? this.published_version
        : null;
      axios
        .post("/guidelines/update/" + this.guideline.id, {
          title: title,
          versionId: versionId,
          version: version,
          published: published,
          published_version: published_version,
          reverselu_url: reverselu_url,
        })
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pull() {
      this.pullDisabled = true;
      axios
        .post("/guidelines/pull/" + this.guideline.id, {
          url: this.guideline.reverselu_url,
        })
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          this.pullDisabled = false;
          this.pdfWarning =
            typeof error.response.data.errors.pdf !== "undefined"
              ? error.response.data.errors.pdf.toString()
              : false;
        });
    },
  },
};
</script>

<style>
.redoutline {
  outline: 1px solid #f00;
}
</style>