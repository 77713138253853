<template>
    <span> {{ data[name]|formatDate }} </span>
</template>

<script>
export default {
    props: {
        data: {},
        name: {},
        click: {},
        classes: {},
    }
}
</script>