/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import moment from 'moment'

//Filters
Vue.filter('formatDate', function (value, format = 'Do MMMM YYYY') {
    if (value) {
        return moment(String(value)).format(format)
    }
    return 'N/A'
});

import DataTable from 'laravel-vue-datatable';

Vue.use(DataTable);

window.Event = new Vue({

});

import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import loadDrillDown from 'highcharts/modules/drilldown';
loadDrillDown(Highcharts);
Vue.use(HighchartsVue, { Highcharts })

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('tr-date', require('./components/TrDate.vue').default);
Vue.component('tr-groups', require('./components/TrGroups.vue').default);
Vue.component('tr-home', require('./components/TrHome.vue').default);
Vue.component('tr-view', require('./components/TrView.vue').default);
Vue.component('tr-select', require('./components/TrSelect.vue').default);
Vue.component('tr-users', require('./components/TrUsers.vue').default);
Vue.component('tr-chart', require('./components/TrChart.vue').default);
Vue.component('tr-drafts', require('./components/TrDrafts.vue').default);
Vue.component('tr-admin', require('./components/TrAdmin.vue').default);
Vue.component('tr-apptoken', require('./components/TrApptoken.vue').default);
Vue.component('tr-edituser', require('./components/TrEdituser.vue').default);
Vue.component('tr-sender', require('./components/TrSender.vue').default);
Vue.component('tr-newuser', require('./components/TrNewuser.vue').default);
Vue.component('tr-ping', require('./components/TrPing.vue').default);
Vue.component('tr-ping-result-success', require('./components/TrPingResultSuccess.vue').default);
Vue.component('tr-ping-result-fail', require('./components/TrPingResultFail.vue').default);
Vue.component('tr-guideline-table', require('./components/TrGuidelineTable.vue').default);
Vue.component('tr-version-table', require('./components/TrVersionTable.vue').default);
Vue.component('tr-version-preview', require('./components/TrVersionPreview.vue').default);
Vue.component('tr-new-guideline', require('./components/TrNewGuideline.vue').default);
Vue.component('tr-guideline-edit', require('./components/TrGuidelineEdit.vue').default);
Vue.component('tr-new-version', require('./components/TrNewVersion.vue').default);
Vue.component('tr-edit-category', require('./components/TrEditCategory.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
