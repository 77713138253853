<template>
  <div>
    <span v-for="group in data[name]" :key="group.id">
      <span class="badge badge-pill" :class="group.colour">{{ group.name}}</span>&nbsp;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
    name: {},
    click: {},
    classes: {}
  }
};
</script>