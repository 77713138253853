<template>
  <button
    :class="classes"
    @click="click(data)"
    :disabled="disabled"
    title="Update"
  >
    {{ name }}
    &nbsp;
    <span>
      <i class="fas fa-comment-alt"></i>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    data: {},
    name: {},
    click: {
      type: Function,
    },
  },
  data() {
    return {
      select_id: "",
    };
  },
  created() {
    Event.$on("ping", (data) => {
      this.select_id = data.id;
    });
  },
  computed: {
    classes: function () {
      return this.data.id == this.select_id
        ? "btn btn-danger btn-sm"
        : "btn btn-secondary btn-sm";
    },
    disabled: function () {
      return !this.data.apptoken;
    },
  },
};
</script>