<template>
  <div>
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-12">
            <data-table
              :columns="columns"
              :per-page="perpage"
              order-by="updated_at"
              order-dir="desc"
              url="/teamreads"
            ></data-table>
          </div>
          <div class="col-3">
            <tr-view />
          </div>
          <div class="col-3">
            <tr-chart ref="piechart" chart="pie" />
          </div>
          <div class="col-6">
            <tr-chart ref="timechart" chart="timeseries" />
          </div>
        </div>
      </div>
      <div class="container col-3">
        <tr-users />
      </div>
    </div>
  </div>
</template>

<script>
import TrDate from "./TrDate";
import TrGroups from "./TrGroups";
import TrSelect from "./TrSelect";
import TrUsers from "./TrUsers";
import TrChart from "./TrChart";

export default {
  data() {
    return {
      perpage: ["5", "10", "25", "50"],
      columns: [
        {
          label: "ID",
          name: "id",
          orderable: true,
        },
        {
          label: "Title",
          name: "title",
        },
        {
          label: "Groups",
          name: "groups",
          component: TrGroups,
        },
        {
          label: "User",
          name: "user.name",
        },
        {
          label: "Date",
          name: "updated_at",
          orderable: true,
          component: TrDate,
        },
        {
          label: "",
          name: "View",
          orderable: false,
          event: "click",
          handler: this.display,
          component: TrSelect,
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => this.reflow(), 3000);
  },
  methods: {
    display(data) {
      Event.$emit("view", data);
    },
    reflow() {
      this.$refs.piechart.$refs.chart.chart.reflow();
      this.$refs.timechart.$refs.chart.chart.reflow();
    },
  },
};
</script>
