<template>
  <b-modal v-model="showModal">
    <template v-slot:modal-header>
      Edit User {{user.id}}
      <b-form-checkbox v-model="user.sender" switch>Sender</b-form-checkbox>
    </template>
    <div class="form-group">
      <label>Name:</label>
      <input type="text" class="form-control" v-model="user.name" />
      <small
        v-if="senderwarning"
        class="form-text text-danger"
      >{{user.name}} needs to change their password from the system default to become a sender</small>
      <br />
      <label>Email:</label>
      <input type="email" class="form-control" v-model="user.email" />
    </div>
    <div v-for="group in groups" :key="group.id" class="form-check">
      <label class="form-check-label">
        <input
          type="checkbox"
          class="form-check-input"
          :checked="checkedgroups.includes(group.id)"
          @click="toggle(group.id)"
        />
        {{ group.name }}
      </label>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="danger" class="mr-auto" @click="deleteUser(user.id)">DELETE USER!</b-button>
      <b-button @click="hideModal">Cancel</b-button>
      <b-button @click="updateUser(user.id)" variant="success">Update User</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      user: {},
      groups: groups,
      checkedgroups: [],
      senderwarning: false,
    };
  },
  created() {
    Event.$on("view", (data) => {
      data.sender = Boolean(data.sender);
      this.user = data;
      this.checkedgroups = data.groups.map((group) => {
        return group.id;
      });
      this.showModal = true;
    });
  },
  methods: {
    updateUser(id) {
      axios
        .post("/users/update/" + id, {
          name: this.user.name,
          email: this.user.email,
          groups: this.checkedgroups,
          admin: this.user.sender,
        })
        .then((response) => {
          if (response.data == "error") {
            this.senderwarning = true;
          } else {
            this.showModal = false;
            this.senderwarning = false;
            this.user = {};
            Event.$emit("reload");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(id) {
      axios
        .post("/users/delete/" + id)
        .then((response) => {
          (this.showModal = false), (this.user = {}), Event.$emit("reload");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideModal() {
      this.showModal = false;
      this.senderwarning = false;
      Event.$emit("reload");
    },
    toggle(id) {
      if (this.checkedgroups.includes(id)) {
        this.checkedgroups = this.checkedgroups.filter((group) => {
          return group != id;
        });
      } else {
        this.checkedgroups.push(id);
      }
    },
  },
};
</script>