<template>
  <div>
    <b-button variant="warning" @click="showModal">New User</b-button>

    <b-modal v-model="modalShow">
      <template v-slot:modal-header>New User</template>
      <div class="form-group">
        <label>Name:</label>
        <input type="text" class="form-control" v-model="newuser.name" aria-describedby="nameHelp" />
        <small v-if="nameValid" id="nameHelp" class="form-text text-danger">{{nameValid}}</small>
        <br />
        <label>Email:</label>
        <input
          type="email"
          class="form-control"
          v-model="newuser.email"
          aria-describedby="emailHelp"
        />
        <small v-if="emailValid" id="emailHelp" class="form-text text-danger">{{emailValid}}</small>
      </div>
      <div v-for="group in groups" :key="group.id" class="form-check" aria-describedby="groupsHelp">
        <label class="form-check-label">
          <input
            type="checkbox"
            class="form-check-input"
            :checked="checkedgroups.includes(group.id)"
            @click="toggle(group.id)"
          />
          {{ group.name }}
        </label>
      </div>
      <small v-if="groupsValid" id="groupsHelp" class="form-text text-danger">{{groupsValid}}</small>
      <template v-slot:modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button @click="createUser()" variant="success">Create User</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalShow: false,
      newuser: {},
      groups: groups,
      checkedgroups: [],
      nameValid: false,
      emailValid: false,
      groupsValid: false,
    };
  },
  methods: {
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
      this.newuser = {};
      this.checkedgroups = [];
      this.nameValid = false;
      this.emailValid = false;
      this.groupsValid = false;
    },
    toggle(id) {
      if (this.checkedgroups.includes(id)) {
        this.checkedgroups = this.checkedgroups.filter((group) => {
          return group != id;
        });
      } else {
        this.checkedgroups.push(id);
      }
    },
    createUser() {
      axios
        .post("/users/store", {
          name: this.newuser.name,
          email: this.newuser.email,
          groups: this.checkedgroups,
        })
        .then((response) => {
          this.modalShow = false;
          this.newuser = {};
          this.checkedgroups = [];
          Event.$emit("reload");
        })
        .catch((error) => {
          this.nameValid =
            typeof error.response.data.errors.name !== "undefined"
              ? error.response.data.errors.name.toString()
              : false;
          this.emailValid =
            typeof error.response.data.errors.email !== "undefined"
              ? error.response.data.errors.email.toString()
              : false;
          this.groupsValid =
            typeof error.response.data.errors.groups !== "undefined"
              ? error.response.data.errors.groups.toString()
              : false;
        });
    },
  },
};
</script>