<template>
  <span>
    <i :class="classes"></i>
  </span>
</template>

<script>
export default {
  props: {
    data: {},
    name: {}
  },
  computed: {
    classes: function() {
      return this.data.apptoken ? "fas fa-check" : "far fa-frown";
    }
  }
};
</script>

<style>
.fa-check {
  color: lightgreen;
}
.fa-frown {
  color: lightcoral;
}
</style>