<template>
  <button :class="classes" @click="click(data)" title="Update">
    <span>
      <i class="far fa-eye"></i>
    </span>
    &nbsp;
    {{ name }}
  </button>
</template>

<script>
export default {
  props: {
    data: {},
    name: {},
    click: {
      type: Function
    }
  },
  data() {
    return {
      select_id: ""
    };
  },
  created() {
    Event.$on("view", data => {
      this.select_id = data.id;
    });
    Event.$on("init", data => {
      this.select_id = data.id;
    });
  },
  computed: {
    classes: function() {
      return this.data.id == this.select_id
        ? "btn btn-success btn-sm"
        : "btn btn-primary btn-sm";
    }
  }
};
</script>