<template>
  <div>
    <iframe :src="src" width="100%" height="1000px"></iframe>
  </div>
</template>

<script>
export default {
  props: ["version"],
  data() {
    return {
      src: "",
    };
  },
  created() {
    this.src = this.version.file_path;
    Event.$on("preview", (data) => {
      this.src = data.file_path;
    });
  },
};
</script>