<template>
  <button :class="classes" @click="click(data)" title="Update">
    <span>
      <i class="far fa-eye"></i>
    </span>
    &nbsp;
    {{ name }}
  </button>
</template>

<script>
export default {
  props: {
    data: {},
    name: {},
    click: {
      type: Function,
    },
  },
  data() {
    return {
      select_id: "",
    };
  },
  created() {
    Event.$on("preview", (data) => {
      this.select_id = data.canonical_id;
    });
  },
  computed: {
    classes: function () {
      return this.data.canonical_id == this.select_id
        ? "btn btn-success btn-sm"
        : "btn btn-primary btn-sm";
    },
  },
};
</script>