<template>
  <div v-show="show">
    <highcharts :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
export default {
  props: ["chart"],
  data() {
    return {
      chartOptions: {},
    };
  },
  created() {
    Event.$on(this.chart, (data) => {
      this.chartOptions = data;
    });
  },
  computed: {
    show: function () {
      return !_.isEmpty(this.chartOptions);
    },
  },
};
</script>