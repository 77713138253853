<template>
  <v-client-table :columns="columns" v-model="data" :options="options" />
</template>

<script>
import { ClientTable } from "vue-tables-2";

Vue.use(ClientTable);

export default {
  data() {
    return {
      columns: ["name", "group", "read", "response_time"],
      data: [],
      options: {
        perPageValues: [],
        perPage: 20,
        headings: {
          name: "Name",
          group: "Group",
          read: "Status",
          response_time: "TTR"
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search Users",
          limit: ""
        },
        cellClasses: {
          read: [
            {
              class: "table-success",
              condition: row => row.read == "Read"
            },
            {
              class: "table-danger",
              condition: row => row.read == "Unread"
            }
          ]
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort",
          down: "fa-sort"
        },
        pagination: {
          show: true,
          dropdown: false,
          chunk: 20,
          edge: false,
          align: "center",
          nav: "fixed"
        },
        customSorting: {
          response_time: function(ascending) {
            return function(a, b) {
              var lastA = a.response_time_seconds;
              var lastB = b.response_time_seconds;
              if (ascending) return lastA >= lastB ? 1 : -1;
              return lastA <= lastB ? 1 : -1;
            };
          }
        }
      }
    };
  },
  created() {
    axios
      .get("/teamreads/users/1000000")
      .then(response => {
        this.data = response.data.users;
        Event.$emit("pie", response.data.pie);
        Event.$emit("timeseries", response.data.timeseries);
        Event.$emit("init", response.data.id);
      })
      .catch(error => {
        console.log(error);
      });
    Event.$on("view", data => {
      axios
        .get("/teamreads/users/" + data.id)
        .then(response => {
          this.data = response.data.users;
          Event.$emit("pie", response.data.pie);
          Event.$emit("timeseries", response.data.timeseries);
        })
        .catch(error => {
          console.log(error);
        });
    });
  }
};
</script>

<style>
.fa {
  padding: 5px;
  color: lightgrey;
}
</style>