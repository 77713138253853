<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">{{ data.title }}</h3>
      <span v-html="data.content"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  created() {
    axios
      .get("/teamreads/content/1000000")
      .then(response => {
        this.data = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    Event.$on("view", data => {
      axios
        .get("/teamreads/content/" + data.id)
        .then(response => {
          this.data = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    });
  }
};
</script>