<template>
  <div>
    <data-table
      :columns="columns"
      :per-page="perpage"
      order-by="canonical_id"
      order-dir="desc"
      :url="url"
    ></data-table>
  </div>
</template>

<script>
import TrDate from "./TrDate";
import TrVersionSelect from "./TrVersionSelect";
import TrVersionPublish from "./TrVersionPublish";

export default {
  props: ["url"],
  data() {
    return {
      perpage: ["5", "10", "25", "50"],
      columns: [
        {
          label: "ID",
          name: "canonical_id",
          orderable: true,
        },
        {
          label: "Version",
          name: "version",
        },
        {
          label: "Date",
          name: "updated_at",
          orderable: true,
          component: TrDate,
        },
        {
          label: "Published Version",
          name: "published",
          component: TrVersionPublish,
        },
        {
          label: "",
          name: "View",
          orderable: false,
          event: "click",
          handler: this.display,
          component: TrVersionSelect,
        },
      ],
    };
  },
  methods: {
    display(data) {
      Event.$emit("preview", data);
    },
  },
};
</script>