<template>
  <div>
    <data-table
      :columns="columns"
      :per-page="perpage"
      order-by="id"
      order-dir="asc"
      url="/users/index"
      ref="myTable"
    ></data-table>
    <tr-edituser />
    <tr-ping-result-success />
    <tr-ping-result-fail />
  </div>
</template>

<script>
import TrGroups from "./TrGroups";
import TrSelect from "./TrSelect";
import TrApptoken from "./TrApptoken";
import TrEdituser from "./TrEdituser";
import TrSender from "./TrSender";
import TrPing from "./TrPing";

export default {
  data() {
    return {
      perpage: ["25", "50"],
      columns: [
        {
          label: "ID",
          name: "id",
          orderable: true,
        },
        {
          label: "Name",
          name: "name",
          orderable: true,
        },
        {
          label: "Email",
          name: "email",
          orderable: true,
        },
        {
          label: "Groups",
          name: "groups",
          component: TrGroups,
        },
        {
          label: "App Connected",
          name: "apptoken",
          component: TrApptoken,
        },
        {
          label: "Test Notification",
          name: "Notify",
          orderable: false,
          event: "click",
          handler: this.ping,
          component: TrPing,
        },
        {
          label: "Sender",
          name: "sender",
          component: TrSender,
        },
        {
          label: "",
          name: "View",
          orderable: false,
          event: "click",
          handler: this.display,
          component: TrSelect,
        },
      ],
    };
  },
  methods: {
    display(data) {
      Event.$emit("view", data);
    },
    ping(data) {
      axios
        .post("/users/ping/" + data.id)
        .then((response) => {
          Event.$emit("ping", data);
          if (response.data.success) {
            Event.$emit("pingresponsesuccess");
          } else {
            Event.$emit("pingresponsefail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    Event.$on("reload", () => {
      this.$refs.myTable.getData();
    });
  },
};
</script>
