<template>
  <div>
    <b-button variant="danger" @click="showModal">New Guideline</b-button>

    <b-modal v-model="modalShow">
      <template v-slot:modal-header
        >New Guideline
        <span class="pull-right"
          >Reverse LU&nbsp;<input type="checkbox" v-model="reverselu"
        /></span>
      </template>
      <div class="form-group">
        <label>Name:</label>
        <input
          type="text"
          class="form-control"
          v-model="name"
          aria-describedby="nameHelp"
        />
        <small v-if="nameValid" id="nameHelp" class="form-text text-danger">{{
          nameValid
        }}</small>
        <br />
        <label v-if="!reverselu">Version:</label>
        <input
          v-if="!reverselu"
          type="text"
          class="form-control"
          v-model="version"
          aria-describedby="versionHelp"
        />
        <small
          v-if="versionValid"
          id="versionHelp"
          class="form-text text-danger"
          >{{ versionValid }}</small
        >
        <label v-if="reverselu">URL:</label>
        <input
          v-if="reverselu"
          type="text"
          class="form-control"
          v-model="url"
          aria-describedby="urlHelp"
        />
        <small v-if="urlValid" id="urlHelp" class="form-text text-danger">{{
          urlValid
        }}</small>
        <small v-if="pdfValid" id="pdfHelp" class="form-text text-danger">{{
          pdfValid
        }}</small>
      </div>
      <template v-slot:modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button
          v-if="!reverselu"
          :disabled="btnDisable"
          @click="createGuideline()"
          variant="success"
          >Create Guideline</b-button
        >
        <b-button
          v-if="reverselu && !btnDisable"
          :disabled="btnDisable"
          @click="createRLUGuideline()"
          variant="success"
          >Create RLU Guideline</b-button
        ><b-spinner
          v-if="reverselu && btnDisable"
          variant="success"
          type="grow"
          label="Spinning"
        ></b-spinner>
      </template>
      <vue-dropzone
        v-if="!reverselu"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-success="uploadSuccess"
        v-on:vdropzone-error="uploadError"
        ><!-- csrf token for laravel -->
        <input type="hidden" name="csrf-token" :value="csrfToken"
      /></vue-dropzone>
      <small v-if="file_pathValid" class="form-text text-danger">{{
        file_pathValid
      }}</small>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      modalShow: false,
      nameValid: false,
      urlValid: false,
      versionValid: false,
      file_pathValid: false,
      btnDisable: false,
      reverselu: false,
      pdfValid: false,
      name: "",
      version: "",
      file_path: "",
      url: "",
      csrfToken: document.head.querySelector('meta[name="csrf-token"]').content,
      dropzoneOptions: {
        url: "/guidelines/upload",
        thumbnailWidth: 150,
        maxFilesize: 10.0,
        uploadMultiple: false,
        maxFiles: 1,
        headers: {
          "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')
            .content,
        },
      },
    };
  },
  methods: {
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
      this.name = "";
      this.version = "";
      this.file_path = "";
      this.nameValid = "";
      this.pdfValid = "";
      this.versionValid = "";
      this.file_pathValid = "";
      this.url = "";
      this.urlValid = "";
    },
    uploadSuccess(response) {
      this.file_path = response.xhr.responseText;
    },
    uploadError(response) {
      var test = JSON.parse(response.xhr.responseText);
      this.file_pathValid = test.errors.file[0];
    },
    createGuideline() {
      this.btnDisable = true;
      axios
        .post("/guidelines/create", {
          name: this.name,
          version: this.version,
          file_path: this.file_path,
        })
        .then((response) => {
          this.modalShow = false;
          this.name = "";
          this.version = "";
          this.file_path = "";
          this.nameValid = "";
          this.versionValid = "";
          this.file_pathValid = "";
          window.open("/guidelines/" + response.data, "_self");
        })
        .catch((error) => {
          this.btnDisable = false;
          this.nameValid =
            typeof error.response.data.errors.name !== "undefined"
              ? error.response.data.errors.name.toString()
              : false;
          this.versionValid =
            typeof error.response.data.errors.version !== "undefined"
              ? error.response.data.errors.version.toString()
              : false;
          this.file_pathValid =
            typeof error.response.data.errors.file_path !== "undefined"
              ? error.response.data.errors.file_path.toString()
              : false;
        });
    },
    createRLUGuideline() {
      this.btnDisable = true;
      axios
        .post("/guidelines/createrlu", {
          name: this.name,
          url: this.url,
        })
        .then((response) => {
          this.modalShow = false;
          this.name = "";
          this.version = "";
          this.file_path = "";
          this.nameValid = "";
          this.urlValid = "";
          this.pdfValid = "";
          this.url = "";
          window.open("/guidelines/" + response.data, "_self");
        })
        .catch((error) => {
          this.btnDisable = false;
          this.nameValid =
            typeof error.response.data.errors.name !== "undefined"
              ? error.response.data.errors.name.toString()
              : false;
          this.urlValid =
            typeof error.response.data.errors.url !== "undefined"
              ? error.response.data.errors.url.toString()
              : false;
          this.pdfValid =
            typeof error.response.data.errors.pdf !== "undefined"
              ? error.response.data.errors.pdf.toString()
              : false;
        });
    },
  },
};
</script>