<template>
  <div>
    <data-table
      :columns="columns"
      :per-page="perpage"
      order-by="updated_at"
      order-dir="desc"
      url="/guidelines/table"
      ref="myTable"
    ></data-table>
  </div>
</template>

<script>
import TrDate from "./TrDate";
import TrPublish from "./TrPublish";
import TrGuidelineSelect from "./TrGuidelineSelect";

export default {
  data() {
    return {
      perpage: ["5", "10", "25", "50"],
      columns: [
        {
          label: "ID",
          name: "id",
          orderable: true,
        },
        {
          label: "Title",
          name: "title",
        },
        {
          label: "Published",
          name: "published",
          orderable: true,
          component: TrPublish,
        },
        {
          label: "Date",
          name: "updated_at",
          orderable: true,
          component: TrDate,
        },
        {
          label: "",
          name: "View",
          orderable: false,
          event: "click",
          handler: this.display,
          component: TrGuidelineSelect,
        },
      ],
    };
  },
  methods: {
    display(data) {
      window.open("/guidelines/" + data, "_self");
    },
  },
  created() {
    Event.$on("reload", () => {
      this.$refs.myTable.getData();
    });
  },
};
</script>