<template>
  <b-modal v-model="showModal">
    <template v-slot:modal-header
      ><h3>
        <span style="color: Red"><i class="fas fa-times"></i></span>&nbsp;Whoa -
        Failure!
      </h3></template
    >
    <div class="d-block text-center">
      <p style="font-size: large">
        Hmm. Something seems to have gone wrong here....
      </p>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="warning" @click="hideModal">Not Cool</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    Event.$on("pingresponsefail", () => {
      this.showModal = true;
    });
  },
  methods: {
    hideModal() {
      this.showModal = false;
      this.response = "";
      Event.$emit("reload");
    },
  },
};
</script>