<template>
  <b-modal v-model="showModal">
    <template v-slot:modal-header
      ><h3><i class="fas fa-check"></i>&nbsp;Success!</h3></template
    >
    <div class="d-block text-center">
      <p style="font-size: large">Everything looks good my end!</p>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="success" @click="hideModal"
        ><i class="far fa-smile"></i>&nbsp;Cool</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    Event.$on("pingresponsesuccess", () => {
      this.showModal = true;
    });
  },
  methods: {
    hideModal() {
      this.showModal = false;
      this.response = "";
      Event.$emit("reload");
    },
  },
};
</script>