<template>
  <button class="btn btn-primary btn-sm" @click="click(data.id)" title="Update">
    <span>
      <i class="far fa-eye"></i>
    </span>
    &nbsp;
    {{ name }}
  </button>
</template>

<script>
export default {
  props: {
    data: {},
    name: {},
    click: {
      type: Function,
    },
  },
  data() {
    return {
      select_id: "",
    };
  },
};
</script>