<template>
  <div v-if="data.length > 0" class="col-3">
    <h3>Drafts</h3>
    <v-client-table :columns="columns" v-model="data" :options="options">
      <a slot="id" slot-scope="props" :href="props.row.id">{{props.row.id}}</a>
    </v-client-table>
  </div>
</template>

<script>
import { ClientTable } from "vue-tables-2";

Vue.use(ClientTable);
export default {
  data() {
    return {
      columns: ["id", "title", "user.name"],
      data: drafts,
      options: {
        perPageValues: [],
        perPage: 20,
        headings: {
          id: "ID",
          title: "Title",
          "user.name": "User"
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search",
          limit: ""
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort",
          down: "fa-sort"
        },
        pagination: {
          show: true,
          dropdown: false,
          chunk: 20,
          edge: false,
          align: "center",
          nav: "fixed"
        }
      }
    };
  }
};
</script>

<style>
.fa {
  padding: 5px;
  color: lightgrey;
}
</style>