<template>
  <span>
    <i :class="classes"></i>
  </span>
</template>

<script>
export default {
  props: {
    data: {},
    name: {}
  },
  computed: {
    classes: function() {
      return this.data.sender ? "fas fa-paper-plane" : "fas fa-times";
    }
  }
};
</script>

<style>
.fa-paper-plane {
  color: goldenrod;
}
</style>