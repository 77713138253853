<template>
  <div class="col">
    <b-button variant="danger" size="sm" @click="showModal"
      >New Version</b-button
    >

    <b-modal v-model="modalShow">
      <template v-slot:modal-header>New Version</template>
      <div class="form-group">
        <label>Version Name:</label>
        <input
          type="text"
          class="form-control"
          v-model="name"
          aria-describedby="nameHelp"
        />
        <small v-if="nameValid" id="nameHelp" class="form-text text-danger">{{
          nameValid
        }}</small>
      </div>
      <template v-slot:modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button
          :disabled="btnDisable"
          @click="uploadVersion()"
          variant="success"
          >New Version</b-button
        >
      </template>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-success="uploadSuccess"
        v-on:vdropzone-error="uploadError"
        ><!-- csrf token for laravel -->
        <input type="hidden" name="csrf-token" :value="csrfToken"
      /></vue-dropzone>
      <small v-if="file_pathValid" class="form-text text-danger">{{
        file_pathValid
      }}</small>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  props: ["guideline_id"],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      modalShow: false,
      nameValid: false,
      file_pathValid: false,
      btnDisable: false,
      name: "",
      file_path: "",
      csrfToken: document.head.querySelector('meta[name="csrf-token"]').content,
      dropzoneOptions: {
        url: "/guidelines/upload",
        thumbnailWidth: 150,
        maxFilesize: 10.0,
        uploadMultiple: false,
        maxFiles: 1,
        headers: {
          "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')
            .content,
        },
      },
    };
  },
  methods: {
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
      this.name = "";
      this.file_path = "";
      this.nameValid = "";
      this.file_pathValid = "";
    },
    uploadSuccess(response) {
      this.file_path = response.xhr.responseText;
    },
    uploadError(response) {
      var test = JSON.parse(response.xhr.responseText);
      this.file_pathValid = test.errors.file[0];
    },
    uploadVersion() {
      this.btnDisable = true;
      axios
        .post("/versions/create/" + this.guideline_id, {
          name: this.name,
          version: this.version,
          file_path: this.file_path,
        })
        .then((response) => {
          this.modalShow = false;
          this.name = "";
          this.file_path = "";
          this.nameValid = "";
          this.file_pathValid = "";
          window.open("/guidelines/" + this.guideline_id, "_self");
        })
        .catch((error) => {
          this.btnDisable = false;
          this.nameValid =
            typeof error.response.data.errors.name !== "undefined"
              ? error.response.data.errors.name.toString()
              : false;
          this.file_pathValid =
            typeof error.response.data.errors.file_path !== "undefined"
              ? error.response.data.errors.file_path.toString()
              : false;
        });
    },
  },
};
</script>